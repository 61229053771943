<template>
  <div class="empty-data">
    Nenhum registro encontrado.
  </div>
</template>

<style lang="scss" scoped>
.empty-data {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--White, #FFF);
  /* topbar */
  box-shadow: 0px 0px 16px 0px rgba(61, 71, 102, 0.08);
  backdrop-filter: blur(4px);
  padding: 24px;
  width: 100%;

  color: var(--Ommed, #2D313D);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
</style>